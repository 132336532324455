<template>
    <div class="permission-denied">
        <a-result status="error" title="权限不足" sub-title="您无权访问此页面" style="width: 80%;">
            <template #extra>
                <a-button key="console" type="primary" @click="toHome">返回主页</a-button>
            </template>

            <div class="desc">
                <p style="font-size: 16px">
                    <strong>造成您无法访问此页面的有以下几个原因:</strong>
                </p>
                <p>
                    <close-circle-outlined :style="{ color: 'red' }" />
                    您的登录已经过期，请重新登录
                </p>
                <p>
                    <close-circle-outlined :style="{ color: 'red' }" />
                    您的账号不是超级管理员账号
                </p>
            </div>
        </a-result>
    </div>
</template>
<script>
import { CloseCircleOutlined } from "@ant-design/icons-vue";
import { useRouter, useRoute } from "vue-router";
    export default ({
    components: {
        CloseCircleOutlined,
    },
    setup() {
        const router = useRouter();
        function toHome() {
            router.push({ name: 'Home'});
        }

        return {
            toHome
        }
    }
});
</script>
<style scoped>
    .permission-denied {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .desc {
        width: 100%;
    }

    .desc p {
        margin-bottom: 1em;
    }
</style>